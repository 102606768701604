<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="DeviantActs"
      :page.sync="page"
      :items-per-page="deviantActsPerPage"
      :server-items-length="totalDeviantActs"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Deviant Acts</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark class="mb-2" v-on="on" v-if="showCreate">Add Deviant Act</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Deviant Act Name"
                      >
                      <v-text-field
                        v-model="editedDeviantAct.act"
                        label="Name"
                        class="mt-n6"
                        outlined
                        name="Deviant Act Name"
                        :error-messages="errors"
                        color="grey"
                        filled
                        dense
                      />
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Deviant Act Description"
                      >
                      <v-textarea
                        v-model="editedDeviantAct.description"
                        label="Description"
                        class="mt-n6"
                        outlined
                        name="Deviant Act Description"
                        :error-messages="errors"
                        color="grey"
                        filled
                        solo
                      >
                      </v-textarea>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn class="success ml-4 mb-4" text @click="save">Save</v-btn>
                <v-btn class="mb-4" color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editDeviantAct(item)"
          v-if="showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click.stop="deviantActDialog(item)"
          v-if="showDelete"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="snackbar"
      top
      :color="color"
      :timeout="timeout"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="setSnackbar(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this act ?</p>
          <v-btn link small color="blue" @click="deleteDeviantAct">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false">No</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDeviantActsPerPage"
            v-model="deviantActsPerPage"
            label="DeviantActs per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="10">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import DeviantAct from '@/models/DeviantAct'
export default {
  data: () => ({
    privileges: null,
    showEdit: false,
    showDelete: false,
    showCreate: false,
    dialog: false,
    deleteDialog: false,
    deviantActIndex: null,
    deviantActItem: null,
    headers: [
      {
        text: 'Names',
        align: 'start',
        sortable: false,
        value: 'act'
      },
      { text: 'Descriptions', value: 'description' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    DeviantActs: [],
    editedIndex: -1,
    editedDeviantAct: new DeviantAct(),
    defaultItem: new DeviantAct(),
    selectDeviantActsPerPage: [2, 5, 10, 15, 20, 25, 30],
    page: 1,
    pageCount: 0,
    deviantActsPerPage: 20,
    totalDeviantActs: 0
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New DeviantAct' : 'Edit DeviantAct'
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser', 'search'])
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.initialize()
      }
    },
    deviantActsPerPage: {
      handler () {
        this.page = 1
        this.initialize()
      }
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    initialize () {
      const pageNumber = this.page - 1
      this.$delinquentService.fetchAllDeviantAct(pageNumber, this.deviantActsPerPage)
        .then(response => {
          if (!response.status) {
            this.DeviantActs = response.content
            this.totalDeviantActs = response.totalElements
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    editDeviantAct (item) {
      this.editedIndex = this.DeviantActs.indexOf(item)
      this.$delinquentService.fetchSingleDeviantAct(item.id)
        .then(response => {
          if (response.id) {
            this.editedDeviantAct = response
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    deviantActDialog (item) {
      this.deviantActIndex = this.DeviantActs.indexOf(item)
      this.deviantActItem = item
      this.deleteDialog = true
    },

    deleteDeviantAct () {
      this.deleteDialog = false
      this.$delinquentService.deleteDeviantAct(this.deviantActItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.DeviantActs.splice(this.deviantActIndex, 1)
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedDeviantAct = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    save () {
      if (this.editedIndex > -1) {
        this.updateDeviantAct()
      } else this.createDeviantAct()
    },
    updateDeviantAct () {
      const deletedDeviantActArray = this.DeviantActs.splice(this.editedIndex, 1)
      this.$delinquentService.saveDeviantAct(this.editedDeviantAct)
        .then(response => {
          if (response.id) {
            this.DeviantActs.splice(this.editedIndex, 1, response)
            this.close()
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.DeviantActs.splice(this.editedIndex, 0, deletedDeviantActArray[0])
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createDeviantAct () {
      this.$delinquentService.saveDeviantAct(this.editedDeviantAct)
        .then(response => {
          if (response.id) {
            this.DeviantActs.push(response)
            this.close()
          } else {
            this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_DEVIANTACTS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showCreate = true : this.showCreate = false
      this.privileges.indexOf('UPDATE_DEVIANTACTS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('DELETE_DEVIANTACTS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
